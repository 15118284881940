body {
	font: 12px "Lucida Grande", serif;
}

.contact-form {
	background: #f7f7f7;
	border-radius: 6px;
	padding: 20px;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
	border: 1px solid #B2B2B2;
}

.contact-form input {
	transition: all 0.3s ease-out;
}

.contact-form input[type="text"] {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form input[type="number"] {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form input[type="tel"] {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}
.contact-form input[type="email"] {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form select {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form option {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form option {
	outline: none;
	border: 1px solid #cccccc;
	box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px rgba(255, 255, 255, 1), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	/*padding: 7px 10px;*/
	padding: 3px 6px;
	background: #f1f1f1;
	color: #b5b5b5;
	/*margin-bottom: 4px;*/
	margin-bottom: 2px;
}

.contact-form input[type="text"]:hover {
	background: #f7f7f7;
}

.contact-form input[type="tel"]:hover {
	background: #f7f7f7;
}

.contact-form input[type="email"]:hover {
	background: #f7f7f7;
}

.contact-form select:hover {
	background: #f7f7f7;
}

.contact-form option:hover {
	background: #f7f7f7;
}

.contact-form input[type="text"]:focus {
	box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-color: transparent;
	color: #565656;
}

.contact-form input[type="tel"]:focus {
	box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-color: transparent;
	color: #565656;
}

.contact-form input[type="email"]:focus {
	box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-color: transparent;
	color: #565656;
}

.contact-form select:focus {
	box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-color: transparent;
	color: #565656;
}

.contact-form option:focus {
	box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-color: transparent;
	color: #565656;
}

.contact-form fieldset {
	border: none;
	padding: 25px 10px 10px 10px;
}

.contact-form legend {
	font: 22px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
	text-transform :uppercase;
	text-shadow: 0 1px 1px #fff;
	color: #1b6d8e;
	padding: 0 5px;
}

.contact-form label {
	display: block;
	font: 12px Arial, Helvetica, sans-serif;
	padding-left: 10px;
	letter-spacing: 1px;
	padding-left: 10px;
	color: #1b6d8e;
	opacity: 0.8;
}
.contact-form input:focus + label {
	opacity: 1.0;
}

.contact-form input:not([type=submit]) {
	width: 100%;
	display: block;
}

.submit-wrap {
	display: none;
}

.contact-form div {
	margin: 0 0 6px 0;
	clear: both;
}

.contact-form div > div {
	padding: 4px;
}

.contact-form .city-wrap {
	width: 69%;
	margin-right: 1%;
}

.contact-form .state-wrap {
	width: 14%;
	margin-right: 1%;
}

.contact-form .state-wrap2 {
	width: 18%;
	/*margin-right: 1%;*/
}

.contact-form .zip-wrap {
	width: 25%;
	display: block !important;
}

.contact-form .zip-wrap2 {
	width: 30%;
	display: block !important;
}

.zip-error {
	color: #d70000;
	font: 12px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
	border-top: 2px dotted #ec9696;
	border-bottom: 2px dotted #ec9696;
	text-align: center;
	padding: 5px 10px;
	margin-top: 10px;
	/*display: none;*/
}


.instructions {
    color: black;
    font: 16px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    text-align: left;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/* Table */
table.paleBlueRows {
    font-family: "Times New Roman", Times, serif;
    /*border: 1px solid #FFFFFF;*/
    width: auto;
    height: auto;
    text-align: center;
    border-collapse: collapse;
  }
  table.paleBlueRows td, table.paleBlueRows th {
    border: 1px solid #FFFFFF;
    padding: 6px 6px;
  }
  
  /*table.paleBlueRows tbody td {*/
    /*font-size: 13px;*/
  /*}*/
 
  /*
  table.paleBlueRows tr:nth-child(even) {
    background: #D0E4F5;
  }
  */
  table.paleBlueRows thead {
    background: #0B6FA4;
    border-bottom: 5px solid #FFFFFF;
  }
  table.paleBlueRows thead th {
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    /*border-left: 2px solid #FFFFFF;*/
  }
  table.paleBlueRows thead th:first-child {
    border-left: none;
  }
  
  table.paleBlueRows tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #D0E4F5;
    border-top: 3px solid #444444;
  }
  table.paleBlueRows tfoot td {
    font-size: 14px;
  }

  /* Create three equal columns that floats next to each other */
.vendorColumn {
	float: left;
  	padding: 10px;
}

.vendorLeft {
	width: 30%;
}

.vendorRight {
	width: 70%;
	align-items: center;
	text-align: left;
}
/* Clear floats after the columns */
.vendorRow:after {
	content: "";
  	display: table;
  	clear: both;
}

button {
	border: 0;
	margin: 20px;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: bold;
	padding: 15px 50px;
	border-radius: 50px;
	color: white;
	outline: none;
	position: relative;
  }
  
  button:before{
	content: '';
	display: block;
	background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.4) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom:0;
	right:0;
	left: 0;
	border-radius: 10px;
	transition: all 1s;
	-webkit-transition: all 1s;
  }

  button:hover:before {
	background-position: left bottom;
  }

  input[type="number"] {
	-moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button, 
  input[type="number"]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
  }

  /* spinner */
  .lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #4cc9f0;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(24px, 0);
	}
  }
  